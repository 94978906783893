import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Checkbox,
  Divider,
  HStack,
  Input,
  Link,
  Radio,
  RadioGroup,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { isEmpty } from 'lodash'
import { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useAppDispatch, useAppSelector } from '../../../app/typedReduxHooks'
import Card from '../../../common/components/Card'
import useApi from '../../../common/utils/api/useApi'
import { useFeatureToggle } from '../../../common/utils/feature-toggle/useFeatureToggle'
import AlertModel from '../../../components/AlertModel'
import AuthenticationModal from '../../../components/AuthenticationModal'
import ResponsiveStack from '../../../components/shared-components/ResponsiveStack'
import {
  blankPatient,
  IhiGender,
  PatientClient,
  PatientIhiSearchResponse,
  PatientIhiSearchSuccessfulResponse,
  selectPatient,
} from '../../../features/patient/PatientContextSlice'
import {
  useCancelPrescriptionMutation,
  useCreatePrescriptionMutation,
} from '../../../services/prescriptions'
import { Patient, Prescription } from '../../../types'
import { ErrorCodes } from '../../../types/errors'
import GoogleAnalyticsTagManager from '../../../utils/google-analytics/GoogleAnalyticsTagManager'
import { useGAContextData } from '../../../utils/google-analytics/useGAContextData'
import { isValidEmailFormat } from '../../../utils/validation-helpers/emailFormatValidate'
import { isMobileNumberInvalid } from '../../../utils/validation-helpers/mobileNumberFormatValidate'
import { isValidPrecriberNumberFormat } from '../../../utils/validation-helpers/prescriberNumberValidate'
import { isValidEntityID } from '../../../utils/validation-helpers/PrescriberValidate'
import { printEvidenceOfPrescription } from '../../PrescriptionsPage/components/EvidenceOfPrescription'
import { ReIssuePrescriptionModal } from '../../PrescriptionsPage/components/ReIssuePrescriptionModal'
import { transformPrescriptions } from '../../PrescriptionsPage/utilities'
import { ClinicClient, HpioValidateResponse } from '../ScriptForm/ClinicContextSlice'
import {
  createPrescriberScriptNumber,
  CreatePrescriberScriptNumberResult,
  createScid,
} from '../ScriptForm/PrescribedItem'
import {
  resetScript,
  setDeliveryState,
  setIsPaperPrescription,
  setScriptId,
  setScriptNo,
  setScriptState,
  uncheckIsPaperPrescription,
} from '../ScriptForm/PrescribedItem/PrescribedItemSlice'
import { HpiiValidateResponse, PrescriberClient } from '../ScriptForm/PrescriberSlice'
import {
  isAuthPhoneRequiredForPrescribedItem,
  isSchedule8Medicine,
  repeatIntervalRequired,
} from '../utilities'
import { printPaperPrescription } from './PaperPrescription'
import ReviewPrescriptionModal from './PreviewPrescriptionModal'
import SendScriptStepperModal, { StepValidationStatus } from './SendScriptStepperModal'
import { rtpmPrecheck } from './services/rtpmPrecheckService'
import { SSO_ORGANIZATION_ID_LIST } from '../../../constants'
import { TenantClient } from '../../../features/tenant/tenantSlice'

interface SendScriptProps {
  disabledSubmit: boolean
  isFormValid: () => boolean
  isActive: boolean
  enableWebhooks: boolean
}

const dateToLocalDate = (date: Date = new Date()) => {
  const offset = date.getTimezoneOffset()
  date = new Date(date.getTime() - offset * 60 * 1000)
  return date.toISOString().split('T')[0]
}

const SendScriptPanel: FunctionComponent<SendScriptProps> = ({
  disabledSubmit,
  isFormValid,
  isActive,
  enableWebhooks,
}) => {
  const dispatch = useAppDispatch()
  const { isFeatureEnabled: isOrgRegistrationAndPaymentFeatureOn } = useFeatureToggle(
    'ORG_REGISTRATION_AND_PAYMENT'
  )
  const {
    sendViaEmail,
    sendViaSMS,
    printPaperToken,
    recipientEmail,
    recipientMobile,
    isPaperPrescription,
  } = useAppSelector((state) => state.newScript)

  const isTheDeliveryMethodValid = () => {
    if (sendViaEmail) {
      return isValidEmailFormat(recipientEmail)
    }
    if (sendViaSMS) {
      return !isMobileNumberInvalid(recipientMobile)
    }
    return !!(printPaperToken || isPaperPrescription)
  }

  const {
    email: patientEmailDefault,
    phone_number: patientMobileDefault,
    address_1: patientAddress,
    family_name: patientFamilyName,
    given_names: patientGivenName,
    gender: patientGender,
    date_of_birth: patientDOB,
    medicare_no: patientMedicareNo,
    medicare_irn: patientMedicareIRN,
    dva_file_no: patientDVA,
    dva_card_colour: patientDVAColour,
  }: Patient = useAppSelector((state) => state.patientDetail.selectedPatient)

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isAlertModelOpen, setIsAlertModelOpen] = useState<boolean>(false)
  const [isPaperPrescriptionConfirmationModalOpen, setIsPaperPrescriptionConfirmationModalOpen] =
    useState<boolean>(false)
  const [isModelOpen, setIsModelOpen] = useState<boolean>(false)
  const [isResendModelOpen, setIsResendModelOpen] = useState<boolean>(false)
  const [disableResend, setDisableResend] = useState<boolean>(true)
  const [isAbortClicked, setIsAbortClicked] = useState<boolean>(false)

  const [isHpiiValid, setHpiiValid] = useState<StepValidationStatus>('NOT_START')
  const [isHpioValid, setHpioValid] = useState<StepValidationStatus>('NOT_START')
  const [isIhiValid, setIhiValid] = useState<StepValidationStatus>('NOT_START')
  const [isScidValid, setScidValid] = useState<StepValidationStatus>('NOT_START')
  const [isScriptNumberValid, setIsScriptNoValid] = useState<StepValidationStatus>('NOT_START')
  const [reauthenticationInfo, setReauthenticationInfo] = useState<{
    isAuthModalOpen: boolean
    isDone: boolean
  }>({
    isAuthModalOpen: false,
    isDone: false,
  })
  const [createScriptFinished, setCreateScriptFinished] =
    useState<StepValidationStatus>('NOT_START')
  const [scid, setScid] = useState<string | undefined>(undefined)
  const [guid, setGuid] = useState<string | undefined>(undefined)
  const [overallError, setOverallError] = useState<string | null>(null)
  const [shouldResetScript, setShouldResetScript] = useState<boolean>(false)
  const [prescription, setPrescription] = useState<Prescription | null>(null)
  const GAContextData = useGAContextData()

  const organization = useAppSelector((state) => state.organization?.data)
  const organizationSettings = organization?.settings
  const isAbleToManagePatient = organizationSettings?.enableManagePatient
  const prescriber = useAppSelector((state) => state.prescriber.prescriber)
  const pFamilyName = prescriber?.familyName ?? ''
  const hpii = prescriber?.hpii ?? ''
  const clinic = useAppSelector((state) => state.clinic.currentClinic)
  const hpioNumber = clinic?.hpio_number ?? ''
  const selectedPatient = useAppSelector((state) => state.patientDetail.selectedPatient)
  const pDVANumber = selectedPatient?.dva_file_no ?? ''
  const drugsState = useAppSelector((state) => state.newScript.prescribedItemList)
  const prescriberScriptNumber = useAppSelector((state) => state.newScript.prescriberScriptNumber)
  const drugs = useMemo(() => drugsState || [], [drugsState])
  const isSSOOrganization = organization?.id && SSO_ORGANIZATION_ID_LIST.includes(organization?.id)

  const hpiiResponseValidator = (response: HpiiValidateResponse | undefined): boolean =>
    response ? response.hpii_status === 'A' : false
  const hpioResponseValidator = (response: HpioValidateResponse | undefined): boolean =>
    response ? response.hpio_status === 'A' : false
  const ihiResponseValidator = (response: PatientIhiSearchResponse | undefined): boolean => {
    response = response as PatientIhiSearchSuccessfulResponse
    return response ? response.HIStatus === 'Active' && response.RecordStatus === 'Verified' : false
  }

  const [isEmergencySupply, setIsEmergencySupply] = useState<boolean>(false)
  const [isSendToPharmacy, setIsSendToPharmacy] = useState<boolean>(false)

  const [cancelPrescription, cancelPrescriptionResult] = useCancelPrescriptionMutation()
  const toast = useToast()
  const { isFeatureEnabled: isRTPMEnabled } = useFeatureToggle('RTPM')

  useEffect(() => {
    if (drugs.length <= 0 || !selectedPatient?.family_name || !clinic || !prescriber) {
      return
    }
    const runRtpmPrecheck = async () => {
      if (!isRTPMEnabled || !organizationSettings?.enableRTPM || !prescriber.enableRtpm) {
        return
      }
      const { data, error } = await rtpmPrecheck({
        patient: selectedPatient,
        clinic,
        prescriber,
        prescribedItems: drugs,
      })
      if (error && error.code === ErrorCodes.NETWORK_CONNECTION_ERROR) {
        toast({
          title: 'RTPM',
          description: <p>{error.message}</p>,
          status: 'error',
          duration: null,
          isClosable: true,
          position: 'top-right',
        })
      }
      if (data && [0, 1, 2].includes(data.code)) {
        toast({
          title: 'RTPM',
          description: (
            <Text>
              {data.alertDescription}
              {data.contextualLinkUrl ? (
                <Text>
                  {' '}
                  Click{' '}
                  <Link href={data.contextualLinkUrl} textDecoration="underline" isExternal>
                    HERE
                  </Link>{' '}
                  to view details.
                </Text>
              ) : null}
            </Text>
          ),
          status: data.code === 0 ? 'success' : data.code === 1 ? 'warning' : 'error',
          duration: data.code === 0 ? 5000 : null,
          isClosable: true,
          position: 'top-right',
        })
      }
    }
    runRtpmPrecheck()
  }, [
    JSON.stringify(drugs.map((drug) => `${drug.quantity}-${drug.numberOfRepeatsAuthorised ?? 0}`)),
    selectedPatient,
  ])

  const {
    trigger: checkHpii,
    loading: hpiiLoading,
    error: hpiiError,
    reset: resetCheckHpiiRequest,
  } = useApi(PrescriberClient.checkHpii, {
    onSuccess: (response) => setHpiiValid(hpiiResponseValidator(response) ? 'VALID' : 'INVALID'),
  })

  const { trigger: publishHICheckErrors } = useApi(TenantClient.publishHICheckErrors)

  const {
    trigger: checkHpio,
    loading: hpioLoading,
    error: hpioError,
    reset: resetCheckHpioRequest,
  } = useApi(ClinicClient.checkHpio, {
    onSuccess: (response) => setHpioValid(hpioResponseValidator(response) ? 'VALID' : 'INVALID'),
  })

  const {
    trigger: checkIhi,
    loading: ihiLoading,
    error: ihiError,
    reset: resetCheckIhiRequest,
  } = useApi(PatientClient.checkIhi, {
    onSuccess: (response) => setIhiValid(ihiResponseValidator(response) ? 'VALID' : 'INVALID'),
  })

  const {
    trigger: generateScidNumber,
    loading: generateScidLoading,
    error: generateScidError,
    reset: resetCreateScidRequest,
  } = useApi(createScid, {
    onSuccess: (scid: string) => {
      setScidValid('VALID')
      setScid(scid)
    },
    onFailure: (error) => {
      setOverallError(error.message)
    },
  })

  const {
    trigger: generateScriptNo,
    loading: isGeneratingScriptNo,
    error: scriptNoGenerationError,
    reset: resetGeneratingScriptNoRequest,
  } = useApi(createPrescriberScriptNumber, {
    onSuccess: (data: CreatePrescriberScriptNumberResult) => {
      setIsScriptNoValid('VALID')
      dispatch(setScriptNo(data.prescriberScriptNumber))
    },
  })

  const abortController = new AbortController()

  const [createPrescription, createPrescriptionResult] = useCreatePrescriptionMutation()
  const createScriptLoading = createPrescriptionResult.isLoading
  const createScriptError = createPrescriptionResult.error as any
  const resetCreateScriptRequest = createPrescriptionResult.reset

  useEffect(() => {
    if (prescription) {
      const { isPaperPrescription, isEmergencySupply, isSendToPharmacy } =
        transformPrescriptions(prescription)
      setDisableResend(isPaperPrescription || isEmergencySupply || isSendToPharmacy)
    }
  }, [prescription])
  const cancelCurrentPrescription = async (scid: string, prescriberScriptNumber: string) => {
    // Delay sending cancel signal
    setTimeout(async () => {
      if (scid) {
        const cancelResult = await cancelPrescription({
          originalGUID: guid ?? '',
          scid: scid ?? '',
          prescriberId: prescriber?.id ?? '',
          patientId: selectedPatient.id,
          items: drugs
            ? drugs.map((drug) => ({
                ...drug,
                productId: drug.productId,
                routeAdministration: drug.routeAdministration,
                numberOfRepeatsAuthorised: drug.numberOfRepeatsAuthorised,
                doctorNotes: drug.doctorsNotes,
                scriptCTGAnnotation: drug.ctgAnnotation,
                prescribedItemReserved01: drug.maximumQuantity,
                repeatIntervals: drug.repeatIntervals ?? 0,
                emergencySupply: drug.emergencySupply,
                snomedctReasonForPrescribe: 0, // TODO: Update to use real value
                sequence: 1,
                prescriberScriptNumber,
              }))
            : [],
          isPaperPrescription: isPaperPrescription,
          sequenceItemsToDelete: [1],
        })
        if ('data' in cancelResult) {
          setCreateScriptFinished('INVALID')
        }
      }
    }, 1000)
  }

  const handleCreatePrescription = async (request: any) => {
    const result = await createPrescription(request)
    if ('data' in result) {
      const data = result.data
      setShouldResetScript(true)
      setPrescription(data.prescription)
      return data
    }

    if ('error' in result) {
      const error = result.error || ({} as any)
      const { status, data } = error
      setOverallError(data.message || data)
      if (status >= 500) {
        setShouldResetScript(true)
        return
      }
    }
  }

  const onAbort = async (scid: string) => {
    abortController.abort()
    setCreateScriptFinished('INVALID')
    setIsAbortClicked(true)
    await cancelCurrentPrescription(scid, prescriberScriptNumber)
    setShouldResetScript(true)
    dispatch(setScriptNo(''))
  }

  const onTimeout = async (scid: string, scriptNumber: string) => {
    abortController.abort()
    setIsAlertModelOpen(true)
    await cancelCurrentPrescription(scid, scriptNumber || prescriberScriptNumber)
    dispatch(setScriptNo(''))
    setScid(undefined)
    setOverallError('The server did not respond to the request within the allocated time.')
  }

  useEffect(() => {
    const errorMessage = createScriptError?.data?.message || createScriptError?.data
    setOverallError(errorMessage || null)
    // Since the object is hard to compare, we can just compare the isError flag
  }, [createPrescriptionResult.isError])

  const checkIfSelectedDrugIncludesS8 = () => drugs.some((drug) => isSchedule8Medicine(drug))

  useEffect(() => {
    if (drugs.some((drug) => drug.emergencySupply)) {
      setIsEmergencySupply(true)
    } else {
      setIsEmergencySupply(false)
    }

    if (drugs.length > 0 && drugs.some((drug) => drug.sendToPharmacy !== '')) {
      setIsSendToPharmacy(true)
    } else {
      setIsSendToPharmacy(false)
    }
  }, [drugs])

  useEffect(() => {
    if (isEmergencySupply || isSendToPharmacy) {
      dispatch(
        setDeliveryState({
          recipientEmail: '',
          recipientMobile: '',
        })
      )
    } else {
      dispatch(
        setDeliveryState({
          recipientEmail: patientEmailDefault,
          recipientMobile: patientMobileDefault,
        })
      )
    }
  }, [isEmergencySupply, isSendToPharmacy, dispatch, patientEmailDefault, patientMobileDefault])

  useEffect(() => {
    dispatch(setIsPaperPrescription(prescriber?.paperPrescriptionOnly || false))
  }, [prescriber?.paperPrescriptionOnly, dispatch])

  const onSubmit = async () => {
    ;[
      setIhiValid,
      setHpiiValid,
      setHpioValid,
      setScidValid,
      setIsScriptNoValid,
      setCreateScriptFinished,
    ].forEach((setter) => setter('NOT_START'))
    setScid(undefined)
    setOverallError(null)
    resetCheckHpiiRequest()
    resetCheckHpioRequest()
    resetCheckIhiRequest()
    resetCreateScidRequest()
    resetCreateScriptRequest()
    resetGeneratingScriptNoRequest()

    if (!isValidPrecriberNumberFormat(prescriber?.prescriberNumber)) {
      const error = 'Prescriber number is missing or invalid'
      setOverallError(error)
      GoogleAnalyticsTagManager.sendPrescriptionCreationErrorEvent({ ...GAContextData, error })
      return
    }

    if (!pFamilyName) {
      const error = 'Prescriber family name is missing or invalid'
      setOverallError(error)
      GoogleAnalyticsTagManager.sendPrescriptionCreationErrorEvent({ ...GAContextData, error })
      return
    }

    if (!hpii) {
      const error = 'Prescriber HPII number is missing or invalid'
      setOverallError(error)
      GoogleAnalyticsTagManager.sendPrescriptionCreationErrorEvent({ ...GAContextData, error })
      return
    }

    if (!prescriber?.qualifications) {
      const error = 'Prescriber qualifications field is missing'
      GoogleAnalyticsTagManager.sendPrescriptionCreationErrorEvent({ ...GAContextData, error })
      setOverallError(error)
      return
    }

    if (!hpioNumber) {
      const error = 'Prescriber HPIO number is invalid'
      GoogleAnalyticsTagManager.sendPrescriptionCreationErrorEvent({ ...GAContextData, error })
      setOverallError(error)
      return
    }

    if (!patientAddress) {
      const error = 'Please make sure your patient has a valid address'
      GoogleAnalyticsTagManager.sendPrescriptionCreationErrorEvent({ ...GAContextData, error })
      setOverallError(error)
      return
    }

    if (!patientGivenName) {
      const error = 'Patient first name is missing or invalid'
      setOverallError(error)
      GoogleAnalyticsTagManager.sendPrescriptionCreationErrorEvent({ ...GAContextData, error })
      return
    }

    if (!patientFamilyName) {
      const error = 'Patient family name is missing or invalid'
      GoogleAnalyticsTagManager.sendPrescriptionCreationErrorEvent({ ...GAContextData, error })
      setOverallError(error)
      return
    }

    if (!patientGender) {
      const error = 'Patient gender is missing or invalid'
      GoogleAnalyticsTagManager.sendPrescriptionCreationErrorEvent({ ...GAContextData, error })
      setOverallError(error)
      return
    }

    if (!patientDOB) {
      const error = 'Patient date of birth is missing or invalid'
      GoogleAnalyticsTagManager.sendPrescriptionCreationErrorEvent({ ...GAContextData, error })
      setOverallError(error)
      return
    }

    if (patientMedicareNo) {
      if (!patientMedicareIRN) {
        const error = 'Patient Medicare IRN is missing or invalid'
        GoogleAnalyticsTagManager.sendPrescriptionCreationErrorEvent({ ...GAContextData, error })
        setOverallError(error)
        return
      }
    } else if (patientDVA) {
      if (!patientDVAColour) {
        const error = 'Patient DVA Card Colour are missing or invalid'
        GoogleAnalyticsTagManager.sendPrescriptionCreationErrorEvent({ ...GAContextData, error })
        setOverallError(error)
        return
      }
    } else {
      const error = 'Patient DVA Number or Medicare Number are missing or invalid'
      GoogleAnalyticsTagManager.sendPrescriptionCreationErrorEvent({ ...GAContextData, error })
      setOverallError(error)
      return
    }

    if (!isValidEntityID(prescriber?.entityId)) {
      const error = 'Prescriber eRx entity ID is missing or invalid'
      GoogleAnalyticsTagManager.sendPrescriptionCreationErrorEvent({ ...GAContextData, error })
      setOverallError(error)
      return
    }

    if (!prescriber || !clinic || !selectedPatient) {
      const error =
        'Please make sure you have provide all the data required(Clinic, Patient, Drugs)'
      setOverallError(error)
      GoogleAnalyticsTagManager.sendPrescriptionCreationErrorEvent({ ...GAContextData, error })
      return
    }

    // check drugs dva numbers
    const drugsValidationResults = drugs.map((drug) => {
      const { isRPBS, patientInstructions, repeatIntervals, numberOfRepeatsAuthorised } = drug
      const isRepeatIntervalRequired = repeatIntervalRequired(
        numberOfRepeatsAuthorised,
        clinic.state,
        drug.productDetails
      )
      const validInterval =
        !isRepeatIntervalRequired ||
        (isRepeatIntervalRequired && repeatIntervals !== 0 && repeatIntervals !== undefined)
      const isTreatmentUseValid =
        !['D', 'F', 'E', 'T'].includes(prescriber?.prescriberType || '') || !isEmpty(drug.treatment)
      let errorMsg = ''
      if (isEmpty(patientInstructions)) {
        errorMsg = 'Please make sure you add instructions to all the durgs.'
      } else if (!(!isRPBS || (isRPBS && pDVANumber))) {
        errorMsg =
          'Please make sure you provide the DVA number when any of selected drug checks the RPBS'
      } else if (!validInterval) {
        errorMsg = 'Please make sure you set minimum repeats interval'
      } else if (!isTreatmentUseValid) {
        errorMsg = 'Please select a valid treatment use'
      }
      return {
        isValid:
          (!isRPBS || (isRPBS && pDVANumber)) &&
          !isEmpty(patientInstructions) &&
          validInterval &&
          isTreatmentUseValid,
        isSchedule8Medicine: isSchedule8Medicine(drug),
        error: errorMsg !== '' ? new Error(errorMsg) : null,
      }
    })
    const isEveryDrugValid = drugsValidationResults.every(({ isValid }) => isValid)
    const hasSchedule8Medicine = drugsValidationResults.some(
      ({ isSchedule8Medicine }) => isSchedule8Medicine
    )

    if (hasSchedule8Medicine && !reauthenticationInfo.isDone && !isSSOOrganization) {
      GoogleAnalyticsTagManager.sendPrescriptionCreationErrorEvent({
        ...GAContextData,
        error: 'Require reauthentication',
      })
      setReauthenticationInfo((state) => ({ ...state, isAuthModalOpen: true }))
      return
    }

    if (!isEveryDrugValid) {
      setOverallError(
        drugsValidationResults.filter(({ isValid }) => !isValid)[0]?.error?.message || null
      )
      GoogleAnalyticsTagManager.sendPrescriptionCreationErrorEvent({
        ...GAContextData,
        error: 'Has invalid Drugs in this prescription',
      })
      return
    }

    // check if drug need a phone Authority
    const isAllDrugsPhoneAuthValid = drugs.every(
      (drug) => !isAuthPhoneRequiredForPrescribedItem(drug) || drug.phoneApprovalAuthorityNumber
    )
    if (!isAllDrugsPhoneAuthValid) {
      const error =
        'Please make sure you provide the phone approval authority number when the selected drug requires a phone Authority'
      setOverallError(error)
      GoogleAnalyticsTagManager.sendPrescriptionCreationErrorEvent({
        ...GAContextData,
        error,
      })
      return
    }
    const hiCheckResult = {
      hpiiResult: hpiiResponseValidator(
        await checkHpii({ hpii: hpii, family_name: pFamilyName, hpio: hpioNumber })
      ),
      hpioResult: hpioResponseValidator(
        await checkHpio({ hpio_number: hpioNumber, hpii_number: hpii })
      ),
      ihiResult: ihiResponseValidator(
        await checkIhi({
          GivenName: selectedPatient.given_names,
          FamilyName: selectedPatient.family_name,
          DOB: selectedPatient.date_of_birth,
          Gender: selectedPatient.gender as IhiGender,
          IhiNumber: selectedPatient.ihi_number || '',
          HpiiNumber: hpii,
          HpioNumber: hpioNumber,
        })
      ),
    }

    const isValid = Object.values(hiCheckResult).every((criterion) => criterion)
    if (!isValid) {
      GoogleAnalyticsTagManager.sendPrescriptionCreationErrorEvent({
        ...GAContextData,
        error: 'Failed to valid hpii or hpio or ihi',
      })
      if (enableWebhooks) {
        publishHICheckErrors({
          patientId: selectedPatient.id,
          isIHIValid: hiCheckResult.ihiResult,
          isHPIIValid: hiCheckResult.hpiiResult,
          isHPIOValid: hiCheckResult.hpioResult,
        })
      }
      return
    }

    const scid = await generateScidNumber({})

    if (!scid) {
      GoogleAnalyticsTagManager.sendPrescriptionCreationErrorEvent({
        ...GAContextData,
        error: 'Scid does not exist',
      })
      return
    }

    let newScriptNo = prescriberScriptNumber
    if (!newScriptNo) {
      const data = await generateScriptNo({})
      newScriptNo = data?.prescriberScriptNumber || ''
    } else {
      setIsScriptNoValid('VALID')
    }
    const guid = uuidv4()

    setGuid(guid)
    setScid(scid)
    dispatch(setScriptNo(newScriptNo))

    if (scid && newScriptNo) {
      let timeoutId = null
      if (organizationSettings?.eRxTimeout) {
        timeoutId = setTimeout(
          () => onTimeout(scid, newScriptNo),
          (organizationSettings?.eRxTimeout || 60) * 1000
        )
      }

      const response = await handleCreatePrescription({
        newPrescription: {
          scid,
          guid,
          isPaperPrescription,
          prescriberId: prescriber.id,
          patientId: selectedPatient.id,
          sendViaEmail,
          sendViaSMS,
          printPaperToken,
          recipientEmail,
          recipientMobile,
          items: drugs.map((drug) => ({
            ...drug,
            productId: drug.productId,
            routeAdministration: drug.routeAdministration,
            numberOfRepeatsAuthorised: drug.numberOfRepeatsAuthorised,
            doctorNotes: drug.doctorsNotes,
            scriptCTGAnnotation: drug.ctgAnnotation,
            prescribedItemReserved01: drug.maximumQuantity,
            repeatIntervals: drug.repeatIntervals ?? 0,
            emergencySupply: drug.emergencySupply,
            snomedctReasonForPrescribe: 0, // TODO: Update to use real value
            sendToPharmacy: drug.sendToPharmacy,
            prescriberScriptNumber: newScriptNo,
          })),
        },
        config: {
          signal: abortController.signal,
          headers: isOrgRegistrationAndPaymentFeatureOn
            ? {
                'billing-feature-toggle': 'enable',
              }
            : undefined,
        },
      })
      timeoutId && clearTimeout(timeoutId)

      if (
        response?.eRxPrescription?.status === 'Success' &&
        response?.prescription?.scid &&
        !isAbortClicked
      ) {
        setCreateScriptFinished('VALID')
        setReauthenticationInfo((state) => ({ ...state, isDone: false }))
        dispatch(setScriptId(response.prescription.prescribedItems[0].prescriberScriptNumber))
      } else {
        GoogleAnalyticsTagManager.sendPrescriptionCreationErrorEvent({
          ...GAContextData,
          error: 'Failed to create prescription in server',
        })
        setCreateScriptFinished('INVALID')
      }
    }
  }

  const INIT_ERX_ERROR = 'Could not connect with eRx service, please try again'
  const SUBMIT_ERX_ERROR =
    'Prescription contains incorrect details. Please make sure you have added all required details and try again'

  const steps = [
    {
      label: 'Validating HPII...',
      isLoading: hpiiLoading,
      isValid: isHpiiValid,
      error: hpiiError,
    },
    {
      label: 'Validating HPIO...',
      isLoading: hpioLoading,
      isValid: isHpioValid,
      error: hpioError,
    },
    {
      label: 'Validating IHI...',
      isLoading: ihiLoading,
      isValid: isIhiValid,
      error: ihiError,
    },
    {
      label: 'Initiating the prescription...',
      isLoading: generateScidLoading || isGeneratingScriptNo,
      isValid: isScidValid === 'VALID' ? isScriptNumberValid : isScidValid,
      error: generateScidError || scriptNoGenerationError ? INIT_ERX_ERROR : null,
    },
    {
      label: 'Sending prescription to eRx...',
      isLoading: createScriptLoading,
      isValid: createScriptFinished,
      error: createPrescriptionResult.isError ? SUBMIT_ERX_ERROR : null,
    },
  ]

  const sendPrescription = () => {
    setIsModelOpen(true)
    onSubmit()
  }

  const onCloseStepperModal = () => {
    setIsModelOpen(false)
    setIsAbortClicked(false)
  }

  const handleUpdateIsPaperPrescription = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (selectedPatient.paper_only) {
      if (!isPaperPrescription) {
        setScriptState({
          isPaperPrescription: true,
        })
      }

      return
    }

    const isChecked = e.target.checked
    if (isPaperPrescription && !isChecked) {
      setIsPaperPrescriptionConfirmationModalOpen(true)
      return
    }

    dispatch(
      setScriptState({
        isPaperPrescription: isChecked,
      })
    )
  }

  const handleUpdateIsPaperPrescriptionConfirmation = () => {
    dispatch(uncheckIsPaperPrescription())
    setIsPaperPrescriptionConfirmationModalOpen(false)
  }

  const onResendButtonClicked = () => {
    setIsResendModelOpen(true)
  }
  const closeReIssueModal = () => {
    setIsResendModelOpen(false)
  }
  const printPaperTokenOnly = isEmergencySupply || isSendToPharmacy

  return (
    <Card p="16px">
      <VStack
        alignItems="stretch"
        sx={{
          input: {
            backgroundColor: 'white',
          },
        }}
      >
        <VStack>
          <ResponsiveStack justifyContent="space-between" width="100%">
            <HStack>
              <Text fontWeight="bold">Date</Text>
              <Input
                type="date"
                disabled
                placeholder="22/07/2022"
                value={dateToLocalDate()}
              ></Input>
            </HStack>

            <Checkbox
              isDisabled={prescriber?.paperPrescriptionOnly || selectedPatient.paper_only}
              isChecked={isPaperPrescription}
              fontWeight="bold"
              onChange={handleUpdateIsPaperPrescription}
            >
              Create Paper Script
            </Checkbox>
          </ResponsiveStack>

          {selectedPatient.paper_only && (
            <Text color="red.300" fontSize="xs" alignSelf="end">
              The checkbox is not able to be changed, because the patient is paper only
            </Text>
          )}
        </VStack>

        {(isEmergencySupply || isSendToPharmacy) && (
          <VStack alignItems="flex-end" spacing="4">
            <HStack>
              <Alert status="warning" variant="solid">
                <AlertIcon />
                NO electronic token will be sent to patient. Please print EOP from prescription
                page.
              </Alert>
            </HStack>
          </VStack>
        )}

        <VStack alignItems={['flex-start', 'flex-end']} spacing="4">
          {isPaperPrescription ? null : (
            <>
              <Divider />
              <HStack>
                <RadioGroup
                  defaultValue="Paper_Token"
                  value={sendViaEmail ? 'Email' : sendViaSMS ? 'SMS' : 'Paper_Token'}
                  onChange={(value: 'Email' | 'SMS' | 'Paper_Token') =>
                    dispatch(
                      setDeliveryState({
                        sendViaEmail: value === 'Email',
                        sendViaSMS: value === 'SMS',
                        printPaperToken: value === 'Paper_Token',
                      })
                    )
                  }
                >
                  <ResponsiveStack fontWeight="bold" alignItems="flex-start">
                    <Radio value="Paper_Token">{`Print paper token only`}</Radio>
                    {printPaperTokenOnly ? null : (
                      <>
                        <Radio value="SMS">{`Send to patient mobile`}</Radio>
                        <Radio value="Email">{`Send to patient email`}</Radio>
                      </>
                    )}
                  </ResponsiveStack>
                </RadioGroup>
              </HStack>
              {sendViaEmail ? (
                <HStack>
                  <Text fontWeight="bold">Patient email</Text>
                  <Input
                    value={recipientEmail}
                    onChange={(e) =>
                      dispatch(
                        setDeliveryState({
                          recipientEmail: e.target.value,
                        })
                      )
                    }
                  ></Input>
                </HStack>
              ) : null}
              {sendViaSMS ? (
                <HStack>
                  <Text fontWeight="bold">Patient mobile</Text>
                  <Input
                    value={recipientMobile}
                    onChange={(e) =>
                      dispatch(
                        setDeliveryState({
                          recipientMobile: e.target.value,
                        })
                      )
                    }
                  ></Input>
                </HStack>
              ) : null}
            </>
          )}
          {reauthenticationInfo.isDone && (
            <Alert status="success" variant="subtle">
              <AlertIcon />
              User verification successful, please start submission
            </Alert>
          )}
          <VStack>
            {!isActive && (
              <Alert status={'error'}>
                <AlertIcon />
                <AlertDescription fontSize="sm">Your account is deactivated</AlertDescription>
              </Alert>
            )}
            <Button
              alignSelf={['center', 'flex-end']}
              disabled={disabledSubmit || !isTheDeliveryMethodValid() || !isActive}
              mt="10"
              size="lg"
              colorScheme="blue"
              width="250px"
              onClick={() => {
                if (!isFormValid()) {
                  return
                }
                if (
                  checkIfSelectedDrugIncludesS8() &&
                  !reauthenticationInfo.isDone &&
                  !isSSOOrganization
                ) {
                  setReauthenticationInfo((state) => ({ ...state, isAuthModalOpen: true }))
                  return
                }
                onOpen()
              }}
            >
              Submit
            </Button>
          </VStack>

          <ReviewPrescriptionModal
            isOpen={isOpen}
            isPaperPrescription={isPaperPrescription}
            isIncludeS8={!!checkIfSelectedDrugIncludesS8()}
            onClose={onClose}
            sendPrescription={sendPrescription}
          />
          {isModelOpen && (
            <SendScriptStepperModal
              steps={steps}
              scid={scid}
              guid={guid}
              error={overallError}
              isModelOpen={isModelOpen}
              disableResend={disableResend}
              onCloseStepperModal={() => {
                if (shouldResetScript) {
                  dispatch(resetScript())
                  isAbleToManagePatient && dispatch(selectPatient(blankPatient))
                  setShouldResetScript(false)
                }
                return onCloseStepperModal()
              }}
              onResendButtonClicked={onResendButtonClicked}
              onPrintButtonClicked={() =>
                isPaperPrescription
                  ? scid && printPaperPrescription({ scid, isNewScript: true })
                  : prescription && printEvidenceOfPrescription(prescription)
              }
              createScriptLoading={createScriptLoading}
              cancelPrescriptionLoading={cancelPrescriptionResult.isLoading}
              onAbort={onAbort}
            />
          )}
          {isResendModelOpen && (
            <ReIssuePrescriptionModal
              isOpen={isResendModelOpen}
              onClose={closeReIssueModal}
              prescription={prescription ?? ({} as Prescription)}
            />
          )}
        </VStack>
        {reauthenticationInfo.isAuthModalOpen && (
          <AuthenticationModal
            isOpen={reauthenticationInfo.isAuthModalOpen}
            onClose={() =>
              setReauthenticationInfo((state) => ({ ...state, isAuthModalOpen: false }))
            }
            onSuccess={() => setReauthenticationInfo({ isDone: true, isAuthModalOpen: false })}
          />
        )}

        {isPaperPrescriptionConfirmationModalOpen && (
          <AlertModel
            isOpen={isPaperPrescriptionConfirmationModalOpen}
            onClose={() => setIsPaperPrescriptionConfirmationModalOpen(false)}
            title="Prescription Type Change Confirmation"
            alertTitle="Are you sure to make the prescription become paperless?"
            alertDescription="We will just keep the first PBS item you selected if you decide to make this the prescription paperless."
            status="warning"
            actions={[
              {
                name: "Yes, I am sure to uncheck the 'Create Paper Script' field",
                action: handleUpdateIsPaperPrescriptionConfirmation,
              },
            ]}
          />
        )}
        <AlertModel
          isOpen={isAlertModelOpen}
          onClose={() => setIsAlertModelOpen(false)}
          title="Timeout"
          alertTitle="Request timed out"
          alertDescription="The server did not respond to the request within the allocated time. If you want to print a paper prescription, you can close the modal and check the Create Paper Script checkbox."
          status="error"
          actions={[]}
        />
      </VStack>
    </Card>
  )
}

export default SendScriptPanel
